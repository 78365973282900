import React, { Component } from 'react';
import Layout from '../components/layout';
import Resume from '../components/resume';
import { Link } from 'gatsby';

import { MainWrapper, Title } from '../components/headings';

// const About = ({ location }) => (
//   <Layout location={location}>
//     <MainWrapper color="#16a085">
//       <Title>About</Title>
//       <Link to="/">This is a link</Link>
//       <p>Here is the about page</p>
//     </MainWrapper>
//   </Layout>
// );

// export default About;

export default class About extends Component {
	render() {
		return (
			<Layout {...this.props}>
				<MainWrapper color="#FFC647">
					<Title>About</Title>
					<p>Howdy, I am Travis Bredehoft.</p>

					<p>
						I'm a Senior Full Stack Designer for TownCloud and formerly a
						Frontend developer for Gaia TV. I also do many different freelance
						projects including graphic design, web apps, web design, and web
						development for projects that I find interesting, challenging or
						exciting.
					</p>

					<p>
						I also enjoy experimenting with photography and videography. Check
						out some of my work on{' '}
						<a
							href="https://www.instagram.com/rzzo/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Instagram
						</a>{' '}
						or{' '}
						<a
							href="https://500px.com/rzzo"
							target="_blank"
							rel="noopener noreferrer"
						>
							500px
						</a>
						.
					</p>

					<p>
						Please <Link to="/contact">contact me</Link> if you would like to
						work on a project together.
					</p>

					<Resume />
				</MainWrapper>
			</Layout>
		);
	}
}
