import React, { Component } from 'react';
import styled from 'styled-components';
import PinIcon from '../images/svgs/pin.svg';
import PdfIcon from '../images/svgs/pdf.svg';
import tbResume from '../downloads/Travis-Bredehoft.pdf';


export default class Resume extends Component {
	render() {
		return (
			<ResumeWrap>
				<Finder />
				<div className="resumeDownload">
					<a href={tbResume} download>
						<PdfIcon className="pdf-icon" />
						Download as PDF
					</a>{' '}
				</div>
				<div className="resume-inside">
					<h3>&#047;&#042; --Resume-- &#042;&#047;</h3>
					<h4>&#047;&#042; SUMMARY OF QUALIFICATIONS &#042;&#047;</h4>
					<ul>
						<li>
							Received a Bachelors degree in Visual Communications at Westwood
							College in Denver, Colorado.
						</li>
						<li>
							Excellent organization, self-motivation, and problem-solving
							skills.
						</li>
						<li>
							Approaches problems, solutions and opportunities with an
							entrepreneurial spirit.
						</li>
						<li>
							Creative, strong communication skills and consistently meets
							deadlines.
						</li>
						<li>Passionate about design, development and new technologies.</li>
					</ul>

					<h4>&#047;&#042; Experience &#042;&#047;</h4>
					<h5>
						&#047;&#047; CTO, CMO, Full Stack designer and Developer at
						TownCloud, Inc.
					</h5>
					<span className="time">&#047;&#047; JAN 2015 - PRESENT</span>
					<span className="time">
						<PinIcon className="pin-icon" />
						Broomfield, CO
					</span>
					<ul>
						<li>
							Leading the UI/UX, products, services and development initiatives
							at TownCloud. Designing and building responsive SaaS web apps in
							Angular 7 and Ruby on Rails with custom backends{' '}
							{'(Postgres and MongoDB)'} and RESTFUL API's. Product development,
							design and building for several municipalities in the United
							States. Leading multiple small but mighty teams to create a large
							set of suites that put local governments into a modern, cloud
							based toolset.
						</li>

						<li>
							Using Angular, NodeJS, Rails to develop a local government SAAS
							ERP.
						</li>

						<li>
							Product and UX/UI lead for most of the app suites, designing and
							development for the current set of tools we are creating. Utilized
							Angular to build a component / design system.
						</li>

						<li>
							Marketing and branding for TownCloud, inc. Setting brand
							guidelines and style guidelines for the company and the current
							set of products. Designed and created TownCloud's logo and brand
							idenity.
						</li>

						<li>
							Rapidly prototyped designs for the design process and user
							testing.
						</li>
					</ul>

					<h5>
						&#047;&#047; Freelance UI/UX designer/developer, Web
						designer/developer, Graphic Designer, Consultant
					</h5>
					<span className="time">&#047;&#047; JAN 2000 - present</span>
					<span className="time">
						<PinIcon className="pin-icon" />
						Fort Collins, CO
					</span>
					<ul>
						<li>
							Work with many different companies to solve branding, marketing,
							and digital problems/opportunities.
						</li>

						<li>Design and build web sites, branding packages, web apps.</li>

						<li>
							Rapidly prototyped designs for the design process and user
							testing.
						</li>
					</ul>

					<h5>&#047;&#047; Front-End developer for Gaiam TV.</h5>
					<span className="time">&#047;&#047; JUNE 2014 - July 2015</span>
					<span className="time">
						<PinIcon className="pin-icon" />
						Louisville, CO
					</span>
					<ul>
						<li>
							Built and maintained the Gaiam TV digital media platform across
							the web site, FireTV, Xbox, Playstation 3, and Roku.
						</li>
						<li>
							Worked with team on an enterprise level, subscription based Drupal
							project with 1.5m+ page views per month, and over 100k paying
							subscribers, helped to architect the project through multiple
							iterationsCustom Drupal module, theme, and panels plugin
							development, Drupal hooks, Drupal Form API, Drupal coding best
							practices, and Drush.
						</li>

						<li>
							Implemented and deployed gaiamtv.com migration and site redesign.
						</li>

						<li>
							Built a new sass framework to replace the stand alone css workflow
						</li>

						<li>
							Worked with team to implement new best practices for "atomic
							design" to speed up development time
						</li>
					</ul>

					<h5>&#047;&#047; Full Stack Web Developer at Bluetent Marketing</h5>
					<span className="time">&#047;&#047; OCT 2007 - MAY 2014</span>
					<span className="time">
						<PinIcon className="pin-icon" />
						Carbondale, CO
					</span>
					<ul>
						<li>
							Planned and designed an Agile approach for the nex-gen release of
							BlueTent's vrFusion product. Leading design, develop and
							implementation of a responsive mobile first solution for the
							Vacation Rental industry.
						</li>

						<li>
							Plan, design, build and maintain web sites for Blue Tent’s entire
							client portfolio.
						</li>

						<li>
							Maintained and built websites for the travel and hotel industry.
						</li>

						<li>
							Took initiative on internal projects to advance direction of
							department.
						</li>

						<li>
							Tested and troubleshot slow application speeds as well as slow
							front end load times on existing websites.
						</li>

						<li>Built responsive, CMS based websites with rapid turnaround.</li>
					</ul>

					<h5>
						&#047;&#047; Director of Communications at The Roaring Fork Club
					</h5>
					<span className="time">&#047;&#047; August 2005 - August 2007</span>
					<span className="time">
						<PinIcon className="pin-icon" />
						Basalt, CO
					</span>
					<ul>
						<li>
							Communicate and market all Member events, parties, news, and
							special events.
						</li>

						<li>
							Advertise through strategic multi-format campaigns including mail,
							e-mail, website, flyers, posters and in person.
						</li>

						<li>
							Design, maintain, and produce a bimonthly newsletter. Plan,
							produce and deliver a yearly member guide book and event calendar.
						</li>
						<li>
							Maintain roaringforkclub.com and continually refine the design.
						</li>

						<li>
							Design, produce, and manage marketing collateral for a specific
							high-end market; including magazine and newspaper ads, brochures,
							flyers, and marketing pieces.
						</li>

						<li>
							Deliver marketing plans for 4 new clubs based on the Roaring Fork
							Club. Design and manage the creation of an intranet to be used
							internally between RFC and the 4 new properties. Contrive, design
							and execute multi-format advertising and PR campaigns. Design,
							administrate and improve prospect database.
						</li>
					</ul>

					<h4>&#047;&#042; EDUCATION &#042;&#047;</h4>
					<ul>
						<li>Westwood College - Visual Communications Bachelors 2006</li>
					</ul>
				</div>
			</ResumeWrap>
		);
	}
}

const ResumeWrap = styled.div`
	background: #222;
	width: 98%;
	margin: 80px auto 50px;
	font-family: 'Inconsolata', monospace;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
	&:before {
		border-radius: 6px 6px 0 0;
		content: '';
		background: #1a1a1a;
		width: 100%;
		display: block;
		height: 20px;
		position: relative;
		top: -4px;
	}
	&:after {
		border-radius: 0 0 6px 6px;
		content: '';
		background: #1a1a1a;
		width: 100%;
		display: block;
		height: 20px;
		position: relative;
		bottom: -4px;
	}
	h3 {
		font-family: 'Inconsolata', monospace;
		font-weight: 400;
	}
	h4 {
		font-family: 'Inconsolata', monospace;
		font-weight: 400;
		color: #aaa;
		margin: 50px 0 20px;
	}
	h5 {
		font-family: 'Inconsolata', monospace;
		font-weight: 400;
		color: #aaa;
		margin: 40px 0 6px;
	}
	.time {
		margin-bottom: 10px;
		display: block;
		font-size: 13px;
		color: #aaa;
	}
	.resume-inside {
		padding: 16px 20px 16px;
	}
	ul {
		margin: 0;
		list-style: none;
	}
	li {
		margin-bottom: 15px;
		color: #eee;
	}

	.pin-icon {
		fill: #aaa;
		width: 12px;
		height: auto;
		margin-right: 4px;
	}

	.pdf-icon {
		width: 24px;
		height: auto;
		margin-right: 8px;
		margin-left: 8px;
	}
.resumeDownload {
	height: 36px;
	padding: 0 16px;
	display: felx;
	justify-content: flex-end;
	align-content: center;
}
.resumeDownload a {
	transition: text-decoration 300ms ease;
}
.resumeDownload a:hover {
	transition: text-decoration 300ms ease;
	text-decoration: underline white;
	text-decoration-thickness: 6px;
  text-underline-offset: 6px;
}
`;

const Finder = styled.span`
	background: #ffc647;
	height: 10px;
	width: 10px;
	display: block;
	border-bottom-right-radius: 3px;
	position: relative;
	top: -17px;
	left: 22px;
	&:before,
	&:after {
		content: '';
		background: #ffc647;
		height: 10px;
		width: 10px;
		display: block;
		border-bottom-right-radius: 4px;
		position: relative;
		top: 0;
	}
	&:before {
		left: -13px;
	}
	&:after {
		top: -10px;
		left: 13px;
	}
`;
